import React from "react";
import { Card, Image, Text } from "@mantine/core";
import { useState } from "react";

export function Trips({ auth }) {

  return (
    <Card withBorder>
        <div style={{display:"flex", flexDirection:"column", alignItems: "center", justifyContent: "space-around"}}>
            <Image src="../../captiva-trip-logo.png" width={300} my={20}></Image>
            <Text size="xl" weight="semibold">
            Request early access to Captiva Trip via <a href="mailto:admin@captivadata.com">email</a>
            </Text>
            <video controls width="339" height="852" src="https://firebasestorage.googleapis.com/v0/b/twodata-dev.appspot.com/o/marketing%2FTrips-Demo.mov?alt=media&token=a0a9007c-cd05-424b-b4c4-2305ce961525" />
        </div>
    </Card>
  );
}

export default Trips;