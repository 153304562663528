import React, { useState } from "react";

import { Button, Modal, Table } from "@mantine/core";

import { VehicleGroupAssetConfig } from "../../components/listings/configurations/assets/vehicle-group-asset-config";
import { AssetEditor } from "../../components/shared/assset-editor";
import { Plus } from "tabler-icons-react";

export function VehicleGroupsTable({ elements, auth, reloadCallback }) {
  const newGroup = {
    name: "",
    description: "",
  };  
  const [editedElement, setEditedElement] = useState(undefined);
  const closeEdit = () => setEditedElement(undefined);
    const rows = elements.map((element) => (
      <tr key={element.id}>
        <td>{element.name}</td>
        <td>{element.description}</td>
        <td>
        <Button
          color={"cap-navy"}
          variant="outline"
          onClick={() => {
            setEditedElement(element);
          }}
        >
          Edit
        </Button>
      </td>
      </tr>
    ));
  
    return (
      <>
        <Table width={"100%"}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        {editedElement && (
          <Modal opened onClose={closeEdit} title="Vehicle group">
            <AssetEditor
              asset={editedElement}
              assetConfig={VehicleGroupAssetConfig}
              collection="fleetGroups"
              auth={auth}
                reloadCallback={() => {reloadCallback(); closeEdit()}}
            ></AssetEditor>
          </Modal>
        )}
        <Button onClick={() => setEditedElement(newGroup)}  fullWidth my="xl" color="cap-navy" variant="outline" leftIcon={<Plus />}>
          Create new
        </Button>
      </>
    );
  }